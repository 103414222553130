import React from 'react';
import { Box, useTheme } from "@mui/material";
import { tokens } from '../../../base/theme';
import Join from './context/CreateButton';




function VirtualClass() {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);



    return (
        <Box
            marginTop={"10%"}
            marginLeft={"30%"}
        >
            <Join />
        </Box>
    );
}

export default VirtualClass;
