import React, { useState, useEffect } from "react";
import { Box, Typography, useTheme, Grid } from "@mui/material";
import { tokens } from "../../../base/theme";
import Header from "../../../components/Header";
import { useNavigate, Link  } from 'react-router-dom';
import GetItemsAdmin from "../../getItemAdmin";

const Subjects = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const navigate = useNavigate();
    const [courseDetails, setCourseDetails] = useState([]);

    useEffect(() => {
        GetItemsAdmin.getCourseAdmin()
            .then((result) => {
                const postData = result.data || [];
                setCourseDetails(postData);
            })
            .catch((error) => {
                console.error("Error fetching post data:", error);
            });
    }, []);

    const boxContents = courseDetails.map((course) => ({
        title: course.name,
        imageUrl: course.course_pic,
        description: `Manage, add or edit ${course.name}`,
        link: `/services/subjects/${course.id}`,
    }));

    return (
        <Box>
            <Header title="Manage Services" subtitle="Choose the boxes to add/edit" />
            <Box marginLeft="1%" marginRight="1%">
                <Grid container spacing={2}>
                    {boxContents.map((content, index) => (
                        <Grid item xs={4} key={index}>
                            <Link to={content.link} style={{ textDecoration: 'none', color: 'inherit' }}>
                                <Box
                                    sx={{
                                        height: "30vh",
                                        border: "4px solid",
                                        borderColor: theme.palette.mode === "dark" ? colors.primary[400] : colors.primary[100],
                                        backgroundImage: `url(${content.imageUrl})`,
                                        backgroundSize: "cover",
                                        backgroundPosition: "center",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        padding: "1rem",
                                    }}
                                >
                                    <Typography variant="h5">{content.title}</Typography>
                                    <Typography variant="body1">{content.description}</Typography>
                                </Box>
                            </Link>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </Box>
    );
};

export default Subjects;



