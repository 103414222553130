import React, { useState, useEffect, useRef, Component } from 'react';
import { Box, useTheme, Button, DialogActions, Dialog, DialogContent, DialogContentText, DialogTitle, Stack, Modal, Typography } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import FilledInput from '@mui/material/FilledInput';
import InputLabel from '@mui/material/InputLabel';
import { tokens } from "../../../base/theme";
import Header from "../../../components/Header";
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { Link, useNavigate, useParams } from 'react-router-dom';
import SaveItemsAdmin from '../../saveItemAdmin';
import GetItemsAdmin from '../../getItemAdmin';
import Select from '@mui/material/Select';
import SmartToyOutlinedIcon from '@mui/icons-material/SmartToyOutlined';
import YouTubeIcon from '@mui/icons-material/YouTube';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';

import CK from '../../../Editor/ck';


const AddMaterial = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [image, setImage] = useState(null);
    const [title, setTitle] = useState(null);
    const [status, setStatus] = useState(null);
    const navigate = useNavigate();
    const [openAiImage, setOpenAiImage] = useState(false);
    const [openVideo, setOpenVideo] = useState(false);
    const [openOcr, setOpenOcr] = useState(false);
    const { lesson_id } = useParams();

    const [lessonDetails, setLessonDetails] = useState([]);


    const functionOpenAiImage=() =>{
        setOpenAiImage(true);
    }
    const functionCloseAiImage=() =>{
        setOpenAiImage(false);
    }

    const functionOpenViedo=() =>{
        setOpenVideo(true);
    }
    const functionCloseVideo=() =>{
        setOpenVideo(false);
    }

    const functionOpenOcr=() =>{
        setOpenOcr(true);
    }
    const functionCloseOcr=() =>{
        setOpenOcr(false);
    }
    
    

    const handleChange = (event) => {
        setStatus(event.target.value);
      };
      
      

    const handleImageChange = (event) => {
        const selectedImage = event.target.files[0];
        setImage(selectedImage);
    };

    const handleAddBlog = async (event) => {
        event.preventDefault(); // Prevent the default form submission behavior
      
        try {
          const success = await SaveItemsAdmin.addBlogAdmin( title, status, image);
          
          if (success) {
            navigate("/blogs-admin");
          } else {
            // Handle login failure and display an error message to the user
            alert("Error Saving data");
          }
        } catch (error) {
          // Handle network or other errors
          console.error("Saving Error:", error);
          alert("An error occurred while saving.");
        }
      }
      

  return (

    <Box>
        {/* Open AI image dialog */}
        <Dialog open={openAiImage} fullWidth maxWidth="lg">
            <DialogTitle> AI Image Generator or Edit </DialogTitle>
            <DialogContent>
                <Stack spacing={2} margin={2}>

                </Stack>
            </DialogContent>
            <DialogActions>
                <Button color='success' variant='contained'>Use Image</Button>
                <Button color='error' variant='contained' onClick={functionCloseAiImage}>Close</Button>
            </DialogActions>
        </Dialog>

        {/* Open video dialog */}
        <Dialog open={openVideo} fullWidth maxWidth="lg">
            <DialogTitle> Paste the link </DialogTitle>
            <DialogContent>
                <Stack spacing={2} margin={2}>
                    <TextField
                        onChange={(e) => setTitle(e.target.value)}
                        label="Enter Lesson Title"
                        id="title"
                        sx={{ m: 1, width: '60.5%' }}
                        variant="filled"
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button color='success' variant='contained'>Save</Button>
                <Button color='error' variant='contained' onClick={functionCloseVideo}>Close</Button>
            </DialogActions>
        </Dialog>

        {/* Open OCR dialog */}
        <Dialog open={openOcr} fullWidth maxWidth="lg">
            <DialogTitle> Upload the PDF or Image for OCR </DialogTitle>
            <DialogContent>
                <Stack spacing={2} margin={2}>

                </Stack>
            </DialogContent>
            <DialogActions>
                <Button color='success' variant='contained'>Use Image</Button>
                <Button color='error' variant='contained' onClick={functionCloseOcr}>Close</Button>
            </DialogActions>
        </Dialog>


        <Header title="Add Material" subtitle="Please upload the neccessary material and then begin creating presentations and other documents" />
            
        <Box sx={{ display: 'flex', flexWrap: 'wrap' }} component="form" noValidate >{/*onSubmit={handleAddBlog}*/}
                <TextField
                onChange={(e) => setTitle(e.target.value)}
                label="Enter Lesson Title"
                id="title"
                sx={{ m: 1, width: '60.5%' }}
                variant="filled"
                />
                <FormControl sx={{ m: 1, width: '25.5%' }} variant="filled">
                    <InputLabel id="status">Status</InputLabel>
                    <Select
                        labelId="status"
                        id="status"
                        value={status}
                        label="status"
                        onChange={handleChange}
                    >
                        <MenuItem value={0}>Draft</MenuItem>
                        <MenuItem value={1}>Publish</MenuItem>
                    </Select>
                </FormControl>
                <FormControl sx={{ m: 3, width: '25%' }} variant="filled">
                    <Input
                        accept="image/*"
                        id="image-upload"
                        type="file"
                        htmlFor="image-upload"
                        onChange={handleImageChange}
                        endAdornment={
                            <InputAdornment position="end">
                                Use AI to Generate or Edit Image
                                <IconButton
                                    onClick={functionOpenAiImage}
                                    aria-label="upload image"
                                    edge="end"
                                    component="label"

                                >
                                    <SmartToyOutlinedIcon></SmartToyOutlinedIcon>
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                    <FormHelperText id="image-upload-helper-text">Image "eg. JPEG, JPG, PNG, SVG"</FormHelperText>
                </FormControl>
                <FormControl sx={{ m: 3, width: '25%' }} variant="filled">
                    <Input
                        accept="image/*"
                        id="image-upload"
                        type="file"
                        htmlFor="image-upload"
                        onChange={handleImageChange}
                        endAdornment={
                            <InputAdornment position="end">
                                or paste Youtube or other sources link
                                <IconButton
                                    onClick={functionOpenViedo}
                                    aria-label="upload image"
                                    edge="end"
                                    component="label"

                                >
                                    <YouTubeIcon></YouTubeIcon>
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                    <FormHelperText id="image-upload-helper-text">Video or Audio "eg. MP4, MKV, MP3, WAV"</FormHelperText>
                </FormControl>
                <FormControl sx={{ m: 3, width: '25%' }} variant="filled">
                    <Input
                        accept="image/*"
                        id="image-upload"
                        type="file"
                        htmlFor="image-upload"
                        onChange={handleImageChange}
                        endAdornment={
                            <InputAdornment position="end">
                                or upload PDF or Image for OCR
                                <IconButton
                                    onClick={functionOpenOcr}
                                    aria-label="upload image"
                                    edge="end"
                                    component="label"

                                >
                                    <DocumentScannerIcon></DocumentScannerIcon>
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                    <FormHelperText id="image-upload-helper-text">Video or Audio "eg. MP4, MKV, MP3, WAV"</FormHelperText>
                </FormControl>

                <Box sx={{ m: 1, width: '93%', height: 600, color: theme.palette.mode === 'dark' ? 'black' : 'inherit' }} >
                 <Typography variant="h2" color={colors.grey[100]} fontWeight="bold" sx={{ mb: "5px" }}>
                    Lesson Description
                 </Typography>
                    <CK />
                </Box>
                <Box style={{ margin: '8px', width: '46%' }}>
                    <Link to={`/create-presentation/${lesson_id}`} style={{ textDecoration: 'none' }}>
                        <Button                            
                            color='warning'
                            variant="contained"
                            
                        >
                            CREATE PRESENTATION
                        </Button>
                    </Link>
                 </Box>
              <Button
                
                sx={{ m: 1, width: '46%' }}
                color='warning'
                variant="contained"
                
              >
                CREATE OTHER DOCUMENTS "eg. docs, sheets, pdf"
              </Button>

                <Button
                //type="submit"
                sx={{ m: 1, width: '93%' }}
                color='success'
                variant="contained"
                
              >
                Save
              </Button>

        </Box> 
    </Box>
  );
};

export default AddMaterial;