import { Box, Typography, useTheme, Button, Grid } from "@mui/material";
import { Link } from "react-router-dom";
import { tokens } from "../../../base/theme";
import Header from "../../../components/Header";
import React, { useState, useEffect } from "react";
import GetItemsAdmin from "../../getItemAdmin";
import { useParams } from 'react-router-dom';
import AddToQueueOutlinedIcon from '@mui/icons-material/AddToQueueOutlined';

const CourseSubjectAdmin = () => {
    const [subjectDetails, setSubjectDetails] = useState([]);
    const { course_id } = useParams();

    useEffect(() => {
        GetItemsAdmin.getCourseSubjectAdmin(course_id)
            .then((result) => {
                // Assuming result.data is the array you want
                const subjectData = result.data || [];
                setSubjectDetails(subjectData);
            })
            .catch((error) => {
                console.error("Error fetching subject data:", error);
            });
    }, [course_id]);
    
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    


    const boxContents = subjectDetails.map((subject) => ({
        title: subject.subject_name,
        imageUrl: subject.course_pic,
        description: `Manage, add or edit ${subject.subject_name}`,
        link: `/services/subjects/${subject.course_id}/${subject.id}`,
    }));

    return (
        <Box>
            <Header title="Manage Subjects" subtitle="Choose the boxes to add/edit" />
            <Box marginLeft="1%" marginRight="1%">
                <Grid container spacing={2}>
                    {boxContents.map((content, index) => (
                        <Grid item xs={4} key={index}>
                            <Link to={content.link} style={{ textDecoration: 'none', color: 'inherit' }}>
                                <Box
                                    sx={{
                                        height: "30vh",
                                        border: "4px solid",
                                        borderColor: theme.palette.mode === "dark" ? colors.primary[400] : colors.primary[100],
                                        backgroundImage: `url(${content.imageUrl})`,
                                        backgroundSize: "cover",
                                        backgroundPosition: "center",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        padding: "1rem",
                                    }}
                                >
                                    <Typography variant="h5">{content.title}</Typography>
                                    <Typography variant="body1">{content.description}</Typography>
                                </Box>
                            </Link>
                        </Grid>
                    ))}
                </Grid>
            </Box>
            <Link to="/add-subject" style={{ textDecoration: 'none' }}>
                <Grid container justifyContent="center"> 
                    <Box sx={{ m: 10}}>
                        <Button 
                            startIcon={<AddToQueueOutlinedIcon />}
                            justifyContent="center"
                            variant="contained"
                            size="large"
                            color = "success"
                            >Add Subject
                        </Button>
                    </Box>
                </Grid>
            </Link>
        </Box>
    );
};

export default CourseSubjectAdmin;