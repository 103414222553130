import { Routes, Route } from "react-router-dom";
import Topbar from "./HodViews/scense/global/Topbar";
import SidebarAdmin from "./HodViews/scense/global/SidebarAdmin";
import { ColorModeContext, useMode } from "./base/theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import DashboardAdmin from "./HodViews/scense/dashboard";
import TeamAdmin from "./HodViews/scense/team";
import AgentAdmin from "./HodViews/scense/agents";
import AddTeam from "./HodViews/scense/team/addTeam";
import AddAgent from "./HodViews/scense/agents/addAgent";
import EditTeam from "./HodViews/scense/team/editTeam";
import EditAgent from "./HodViews/scense/agents/editAgent";
import SocialMedia from "./HodViews/scense/websites/socialMedia";
import WebsiteComponentsAdmin from "./HodViews/scense/websites/websiteComponents";
import BlogsAdmin from "./HodViews/scense/websites/bolgs";
import AddBlog from "./HodViews/scense/websites/addBlog";
import VirtualClass from "./webrtc/client/src/virtualCalss";
import { RoomProvider } from "./webrtc/client/src/context/RoomContext";
import { Room } from "./webrtc/client/src/Room";
import Services from "./HodViews/scense/services";
import CoursesAdmin from "./HodViews/scense/courses";
import Subjects from "./HodViews/scense/subjects";
import CourseSubjectAdmin from "./HodViews/scense/subjects/courseSubject";
import SubjectLessonAdmin from "./HodViews/scense/lessons/subjectLesson";
import CourseSubjectLessonAdmin from "./HodViews/scense/lessons/courseSubjectsLesson";
import LessonsAdmin from "./HodViews/scense/lessons/lessons";
import AddMaterial from "./HodViews/scense/material";
//import CreatePresentationAdmin from "./HodViews/scense/material/createPresentation";


const HodViewsLayout = ({ children }) => {
    const [theme, colorMode] = useMode();
  
    return (
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <div className="app">
            <SidebarAdmin />
            <main className="content">
              <Topbar />
              {children}
            </main>
          </div>
        </ThemeProvider>
      </ColorModeContext.Provider>
    );
  };

const AdminPages = () => (


    <Routes>
    
    <Route
        path="/virtual-class"
        element={
          <HodViewsLayout>
            <RoomProvider>
              <VirtualClass />
            </RoomProvider>
          </HodViewsLayout>
        }
      />
      <Route
        path="/room/:id"
        element={
            <RoomProvider>
              <Room />
            </RoomProvider>
        }
      />
      <Route
        path="/dashboard-admin"
        element={
          <HodViewsLayout>
            <DashboardAdmin />
          </HodViewsLayout>
        }
      />
      <Route
        path="/team-admin"
        element={
          <HodViewsLayout>
            <TeamAdmin />
          </HodViewsLayout>
        }
      />
      <Route
        path="/agent-admin"
        element={
          <HodViewsLayout>
            <AgentAdmin />
          </HodViewsLayout>
        }
      />
      <Route
        path="/addteam"
        element={
          <HodViewsLayout>
            <AddTeam />
          </HodViewsLayout>
        }
      />
      <Route
        path="/add-agent-admin"
        element={
          <HodViewsLayout>
            <AddAgent />
          </HodViewsLayout>
        }
      />
      <Route
        path="/editTeam/:user_id"
        element={
          <HodViewsLayout>
            <EditTeam />
          </HodViewsLayout>
        }
      />
      <Route
        path="/editAgent/:user_id"
        element={
          <HodViewsLayout>
            <EditAgent />
          </HodViewsLayout>
        }
      />
      <Route
        path="/social-media"
        element={
          <HodViewsLayout>
            <SocialMedia />
          </HodViewsLayout>
        }
      />
      <Route
        path="/website-components-admin"
        element={
          <HodViewsLayout>
            <WebsiteComponentsAdmin />
          </HodViewsLayout>
        }
      />
      <Route
        path="/blogs-admin"
        element={
          <HodViewsLayout>
            <BlogsAdmin />
          </HodViewsLayout>
        }
      />
      <Route
        path="/add-blog"
        element={
          <HodViewsLayout>
            <AddBlog />
          </HodViewsLayout>
        }
      />
      <Route
        path="/services"
        element={
          <HodViewsLayout>
            <Services />
          </HodViewsLayout>
        }
      />
      <Route
        path="/services/courses"
        element={
          <HodViewsLayout>
            <CoursesAdmin />
          </HodViewsLayout>
        }
        
      />
      <Route
        path="/services/subjects"
        element={
          <HodViewsLayout>
            <Subjects />
          </HodViewsLayout>
        }
        
      />
      <Route
        path="/services/subjects/:course_id"
        element={
          <HodViewsLayout>
            <CourseSubjectAdmin />
          </HodViewsLayout>
        }
        
      />
        <Route
        path="/services/subjects_lessons"
        element={
          <HodViewsLayout>
            <SubjectLessonAdmin />
          </HodViewsLayout>
        }
        
      />
      <Route
        path="/services/subjects_lesson/:course_id"
        element={
          <HodViewsLayout>
            <CourseSubjectLessonAdmin />
          </HodViewsLayout>
        }
        
      />
      <Route
        path="/services/subjects_lesson/:course_id/:subject_id"
        element={
          <HodViewsLayout>
            <LessonsAdmin />
          </HodViewsLayout>
        }
        
      />
      <Route
        path="/add-material/:lesson_id"
        element={
          <HodViewsLayout>
            <AddMaterial />
          </HodViewsLayout>
        }
        
      />

  {/*  <Route
        path="/create-presentation/:lesson_id"
        element={
          <HodViewsLayout>
            <CreatePresentationAdmin />
          </HodViewsLayout>
        }
        
      />*/}
    </Routes>
)

export default AdminPages;
