import { Button } from "@mui/material"
import { useContext } from "react";
import { RoomContext } from "./RoomContext";

const Join = () =>{
    const {ws} = useContext(RoomContext)
    const createRoom = () => {
        ws.emit("create-room")
    }
    return (
        <Button
            onClick={createRoom}
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            >
            Start new meeting
        </Button>
    )
    
}

export default Join;
