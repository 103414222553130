import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { RoomContext } from './context/RoomContext';
import { Box } from '@mui/material';
import { VideoPlayer } from './context/VidoePlayer';

export const Room = () => {
    const { id } = useParams();
    const { ws, me, stream, peers } = useContext(RoomContext);

    useEffect(() => {
        if (me) ws.emit("join-room", { roomId: id, peerId: me._id });
    }, [id, ws, me]);
    
    return (
        <Box>
            <h1>Room id {id}</h1>
            <VideoPlayer key="local" stream={stream} /> {/* Local video */}
            {Object.keys(peers).map((peerId) => (
                <VideoPlayer key={peerId} stream={peers[peerId].stream} />
            ))}
        </Box>
    );
};
